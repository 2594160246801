import { MotionDiv } from "@/components/ui/MotionDiv";
import { ChevronRight } from "@/assets/icons/mdi-chevron-right";
import Link from "next/link";
import { useRouter } from "next/navigation";

interface PrimaryButtonProps {
  text: string;
  navigateTo?: string;
  variant?: "primary" | "secondary" | "unique";
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const PrimaryButton = ({
  text,
  navigateTo,
  variant = "primary",
  onClick,
  className,
  disabled,
}: PrimaryButtonProps) => {
  const router = useRouter();

  const handleClick = () => {
    if (onClick) onClick();
    if (navigateTo) router.push(navigateTo);
  };

  const getBackgroundStyle = (variant: string) => {
    switch (variant) {
      case "primary":
        return "bg-primary text-white relative overflow-hidden transform -skew-x-12 before:absolute before:inset-0 before:right-full before:z-[-1] before:bg-white before:transition-all before:duration-500 group-hover:before:right-0 group-hover:text-primary";
      case "secondary":
        return "bg-white text-primary relative overflow-hidden transform -skew-x-12 before:absolute before:inset-0 before:right-full before:z-[-1] before:bg-black before:transition-all before:duration-500 group-hover:before:right-0 group-hover:text-white";
      case "unique":
        return "bg-gradient-to-r from-[#F9F383] via-[#F14553] to-[#19477E] text-white relative overflow-hidden transform -skew-x-12 before:absolute before:inset-0 before:right-full before:z-[-1] before:bg-gradient-to-r before:from-[#19477E] before:via-[#F14553] before:to-[#F9F383] before:transition-all before:duration-500 group-hover:before:right-0";
      default:
        return "bg-white text-primary relative overflow-hidden transform -skew-x-12 before:absolute before:inset-0 before:right-full before:z-[-1] before:bg-primary before:transition-all before:duration-500 group-hover:before:right-0 group-hover:text-white";
    }
  };

  const buttonContent = (
    <span className="inline-flex skew-x-12 transform items-center gap-2">
      {text}
      <ChevronRight className="h-5 w-5" />
    </span>
  );

  if (!navigateTo) {
    return (
      <MotionDiv className="group w-fit">
        <button
          onClick={handleClick}
          disabled={disabled}
          className={`text-md relative w-fit px-6 py-2 font-medium uppercase tracking-wider transition-colors duration-500 ${getBackgroundStyle(variant)} ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
        >
          {buttonContent}
        </button>
      </MotionDiv>
    );
  }

  return (
    <Link
      href={navigateTo}
      className={`w-fit ${disabled ? "pointer-events-none" : ""}`}
    >
      <MotionDiv className="group">
        <button
          onClick={handleClick}
          disabled={disabled}
          className={`text-md relative w-fit px-6 py-2 font-medium uppercase tracking-wider transition-colors duration-500 ${getBackgroundStyle(variant)} ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
        >
          {buttonContent}
        </button>
      </MotionDiv>
    </Link>
  );
};
