"use client";

import Link from "next/link";
import logo from "@/assets/images/logo/logo.png";
import Image from "next/image";
import { FormkitFacebook } from "@/assets/icons/formkit-facebook";
import { FormkitInstagram } from "@/assets/icons/formkit-instagram";
import { FormkitLinkedin } from "@/assets/icons/formkit-linkedin";

export const Footer = () => {
  const socialLinks = [
    {
      name: "Instagram",
      icon: FormkitInstagram,
      href: "https://www.instagram.com/tkd_argenteuil_centreville/",
    },
    {
      name: "Facebook",
      icon: FormkitFacebook,
      href: "https://www.facebook.com/MudoClub",
    },
    {
      name: "Linkedin",
      icon: FormkitLinkedin,
      href: "https://www.linkedin.com/company/106003545",
    },
  ];

  const addresses = [
    {
      name: "1 impasse Grégoire Collas 95100 ARGENTEUIL",
      href: "https://maps.app.goo.gl/tDibXDE5NVnGB5PT9",
    },
    {
      name: "6 allée Romain Rolland 95100 ARGENTEUIL",
      href: "https://maps.app.goo.gl/fKW5XxW4ntoQmrG57",
    },
  ];

  const navLinks = [
    { name: "Accueil", href: "/" },
    { name: "Tarifs", href: "/tarifs" },
    { name: "Blog & Évènements", href: "/blog-evenements" },
    { name: "Galerie", href: "/galerie" },
    { name: "Contact", href: "/contact" },
  ];

  const blogLinks = [
    { name: "Nos articles", href: "/blog-evenements?category=blog" },
    { name: "Évènements à venir", href: "/blog-evenements?category=events" },
    { name: "Évènements passés", href: "/blog-evenements#passed-events" },
  ];

  return (
    <footer className="bg-[#222222] px-4 py-20 text-white ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="flex flex-col justify-between gap-16">
        {/* Brand Section */}
        <div className="">
          <div className="flex flex-col justify-between gap-12 sm:flex-row">
            <Link href="/" className="flex items-center gap-4">
              <Image
                src={logo}
                alt="Mudo Club Argenteuil Logo"
                width={48}
                height={48}
                loading="lazy"
                className="h-12 w-12"
              />
              <div className="flex flex-col">
                <span className="text-sm leading-tight xs:text-xl">
                  MUDO CLUB ARGENTEUIL
                </span>
                <span className="text-xs leading-tight xs:text-base">
                  TAEKWONDO
                </span>
              </div>
            </Link>
            <div className="flex gap-6">
              {socialLinks.map((social) => (
                <Link
                  key={social.name}
                  href={social.href}
                  target="_blank"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-white transition-colors hover:bg-gray-200"
                >
                  <social.icon className="h-6 w-6 text-primary" />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation Section */}
        <div className="">
          <div className="grid grid-cols-1 gap-12 ph:grid-cols-2 md:grid-cols-3 md:gap-20 xl:grid-cols-4">
            {/* Services Column */}
            <div className="space-y-6">
              <h3 className="text-lg">NAVIGATION</h3>
              <div className="flex flex-col gap-4">
                {navLinks.map((link) => (
                  <Link
                    href={link.href}
                    key={link.name}
                    className="text-[#C5C5C3] transition-colors hover:text-white"
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Blog & Events Column */}
            <div className="space-y-6">
              <h3 className="text-lg">BLOG & ÉVÈNEMENTS</h3>
              <div className="flex flex-col gap-4">
                {blogLinks.map((link) => (
                  <Link
                    href={link.href}
                    key={link.name}
                    className="text-[#C5C5C3] transition-colors hover:text-white"
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Addresses Column */}
            <div className="space-y-6">
              <h3 className="text-lg">ADRESSES</h3>
              <div className="flex flex-col gap-4">
                {addresses.map((link) => (
                  <Link
                    href={link.href}
                    key={link.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#C5C5C3] transition-colors hover:text-white"
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-24 flex flex-col items-center justify-between gap-6 border-t border-zinc-800 pt-8 md:flex-row md:gap-12">
        <div className="w-full text-center md:w-auto md:text-left">
          <p className="text-sm text-gray-400 md:order-1">
            © 2024 <span className="text-white">Mudo Club Argenteuil.</span>{" "}
            Tous droits réservés.
          </p>

          <p className="text-center text-sm text-gray-400 md:text-left">
            Site développé par{" "}
            <Link
              href="https://virtuosa.fr"
              target="_blank"
              className="transition-colors hover:text-gray-300"
            >
              <span className="text-white">Virtuosa.</span>
            </Link>
          </p>
        </div>

        <div className="flex shrink-0 gap-8 text-sm text-white md:order-2">
          <Link
            href="/mentions-legales"
            className="whitespace-nowrap transition-colors hover:text-gray-300"
          >
            Mentions légales
          </Link>
        </div>
      </div>
    </footer>
  );
};
