"use client";

import { useState, useRef, useEffect } from "react";
import { MotionDiv, AnimatePresence } from "@/components/ui/MotionDiv";
import Link from "next/link";
import logo from "@/assets/images/logo/logo.png";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";
import { BiCross } from "@/assets/icons/bi-cross";

const navLinks = [
  { name: "Accueil", href: "/" },
  { name: "Tarifs", href: "/tarifs" },
  { name: "Blog & Évènements", href: "/blog-evenements" },
  { name: "Galerie", href: "/galerie" },
  { name: "Contact", href: "/contact" },
];

interface HeaderProps {
  className?: string;
}

export const Header = ({ className = "" }: HeaderProps) => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPassedHero, setIsPassedHero] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const lastScrollY = useRef(0);
  const [mounted, setMounted] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? "" : "hidden";
  };

  useEffect(() => {
    setMounted(true);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsVisible(currentScrollY < lastScrollY.current || currentScrollY < 50);
      setIsPassedHero(currentScrollY > 50);
      lastScrollY.current = currentScrollY;
    };

    handleScroll(); // Initial check
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "";
    };
  }, []);

  if (!mounted) {
    return null; // ou un placeholder/skeleton
  }

  const menuVariants = {
    closed: {
      opacity: 0,
      transition: { duration: 0.5 },
    },
    open: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const contentVariants = {
    closed: { y: 50, opacity: 0 },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  const linkVariants = {
    closed: { y: 20, opacity: 0 },
    open: (i: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: 0.5 + i * 0.1, duration: 0.5 },
    }),
  };

  return (
    <>
      <header
        ref={headerRef}
        className={`fixed left-0 right-0 z-40 px-4 py-4 transition-all duration-300 ph:px-24 md:px-48 lg:px-72 xl:px-96 ${className} ${
          isVisible ? "translate-y-0" : "-translate-y-[calc(100%+1rem)]"
        } ${
          isPassedHero
            ? "top-0 h-32 bg-primary text-white"
            : "!h-fit bg-transparent py-8 text-white"
        }`}
      >
        <div className="flex h-full items-center justify-between">
          <Link
            href="/"
            className="z-50 flex items-center gap-4"
            onClick={() => isMenuOpen && toggleMenu()}
          >
            <Image
              src={logo}
              alt="Mudo Club Argenteuil Logo"
              width={48}
              height={48}
              priority={true}
              className="h-12 w-12"
            />
            <div className="hidden flex-col ph:flex">
              <span className="text-xl leading-tight">
                MUDO CLUB ARGENTEUIL
              </span>
              <span className="text-base leading-tight">TAEKWONDO</span>
            </div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden items-center gap-6 xl:flex">
            {navLinks.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="underlineEffect whitespace-nowrap text-lg uppercase tracking-wider transition-colors duration-300"
              >
                {item.name}
              </Link>
            ))}
            <div>
              <PrimaryButton
                text="S'inscrire"
                navigateTo="/inscription"
                variant="secondary"
              />
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="z-50 xl:hidden"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <BiCross className="h-8 w-8 text-white" />
            ) : (
              <div className="flex flex-col gap-2">
                <span className="block h-[2px] w-8 bg-white transition-all duration-300" />
                <span className="block h-[2px] w-8 bg-white transition-all duration-300" />
                <span className="block h-[2px] w-8 bg-white transition-all duration-300" />
              </div>
            )}
          </button>
        </div>
      </header>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMenuOpen && (
          <MotionDiv
            className="fixed inset-0 z-30 bg-primary xl:hidden"
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <MotionDiv
              className="flex h-full flex-col items-center justify-center px-4"
              variants={contentVariants}
            >
              <div className="flex w-full flex-col items-center space-y-8 text-center">
                {navLinks.map((item, index) => (
                  <MotionDiv
                    key={item.name}
                    custom={index}
                    variants={linkVariants}
                  >
                    <Link
                      href={item.href}
                      className="underlineEffect block text-3xl font-light text-white transition-colors duration-300"
                      onClick={() => {
                        toggleMenu();
                      }}
                    >
                      {item.name}
                    </Link>
                  </MotionDiv>
                ))}
                <MotionDiv
                  variants={linkVariants}
                  custom={navLinks.length}
                  className="flex w-full justify-center"
                >
                  <PrimaryButton
                    text="S'inscrire"
                    navigateTo="/inscription"
                    variant="secondary"
                    onClick={() => isMenuOpen && toggleMenu()}
                  />
                </MotionDiv>
              </div>
            </MotionDiv>
          </MotionDiv>
        )}
      </AnimatePresence>
    </>
  );
};
